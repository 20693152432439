<template>
  <div class="d-flex mb-4 align-items-center">
    <div class="title mr-auto">
      <h4 class="title">Pendidikan</h4>
    </div>
    <router-link
      class="btn btn-add"
      v-if="isEditable"
      :to="{ name: 'InsertEducation', params: { id: id_karyawan } }"
      ><span style="font-size: 20px; vertical-align: sub">+</span>
      Tambah</router-link
    >
  </div>
  <div class="table-responsive">
    <table
      class="table table-bordered table-striped table-hover"
      id="tableEducation"
    >
      <thead>
        <tr>
          <th sWtyle="width:25%">Jenjang</th>
          <th sWtyle="width:20%">Institusi</th>
          <th style="width: 20%">Jurusan</th>
          <th style="width: 17%">Tahun Mulai</th>
          <th style="width: 17%">Tahun Selesai</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(value, index) in list"
          :key="index"
          data-toggle="tooltipEdu"
          data-placement="top"
          title="Klik untuk melihat detail"
          @click="detail(value.id)"
          style="cursor: pointer"
        >
          <td>{{ value.level }}</td>
          <td>{{ value.institusi }}</td>
          <td>{{ value.jurusan }}</td>
          <td>{{ value.tahun_mulai }}</td>
          <td>{{ value.tahun_selesai }}</td>
        </tr>
        <tr v-if="list.length === 0">
          <td colspan="5" class="text-center">Tidak ada data</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";
import moment from "moment";
import "moment/locale/id";

// import TableLite from 'vue3-table-lite'
export default {
  name: "EducationEmployee",
  components: {
    // FooterDashboard
    // Select2,
    // Datepicker
    // TableLite
  },

  props: {
    listPendidikan: Object,
    height: Number,
    isEditable: Boolean,
  },

  data() {
    return {
      list: this.$props.listPendidikan,
      id_karyawan: this.$route.params.id,
    };
  },

  mounted() {
    // $("#tableEducation").DataTable({
    //   info: false,
    // });
    this.tooltip();
  },
  methods: {
    tooltip() {
      $('[data-toggle="tooltipEdu"]').tooltip();
    },

    monthFormat(string) {
      return moment(string + "-01").format("MMMM YYYY");
    },

    detail(id_edu) {
      if (this.$props.isEditable) {
        $('[data-toggle="tooltipEdu"]').tooltip("hide");
        this.$router.push({
          name: "InsertEducation",
          params: {
            id: this.id_karyawan,
            nomor: id_edu,
          },
        });
      }
    },
    showModal() {
      // $("#modalTransfer").show("modal");
      console.log("asdasd");
    },
    myChangeEvent(val) {
      console.log(val);
    },
    mySelectEvent({ id, text }) {
      console.log({
        id,
        text,
      });
    },
  },
  watch: {
    listPendidikan: function (newVal) {
      this.list = newVal;
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>
