<template>
  <div class="d-flex mb-4 align-items-center">
    <div class="title mr-auto">
      <h4 class="title">Keluarga</h4>
    </div>
    <!-- <button class="btn btn-toska mr-2">
      <img src="/img/icon-import.svg" alt="" class="mr-2" /> Import
    </button>
    <button class="btn btn-orange mr-2">
      <img
        src="/img/icon-export.svg"
        alt=""
        class="mr-2"
        style="width: 18px"
      />Export
    </button> -->
    <router-link
      class="btn btn-add"
      v-if="isEditable"
      :to="{ name: 'InsertFamily', params: { id: id_karyawan } }"
      ><span style="font-size: 20px; vertical-align: sub">+</span>
      Tambah</router-link
    >
  </div>
  <div class="table-responsive">
    <table
      class="table table-bordered table-striped table-hover"
      id="tableFamily"
    >
      <thead>
        <tr>
          <th style="width: 30%">Nama</th>
          <th style="width: 20%">Hubungan</th>
          <th style="width: 30%">Status Pernikahan</th>
          <th style="width: 20%">Pekerjaan</th>
          <th style="width: 15%">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(value, index) in list"
          :key="index"
          data-toggle="tooltipFamily"
          data-placement="top"
          title="Klik untuk melihat detail"
          @click="detail(value.id)"
          style="cursor: pointer"
        >
          <td>{{ value.nama }}</td>
          <td>{{ value.hubungan_keluarga == 'pasangan' ? 'Suami/Istri' : value.hubungan_keluarga == 'anak' ? 'Anak' : 'Lainnya' }}</td>
          <td>{{ value.status_pernikahan }}</td>
          <td>{{ value.pekerjaan ? value.pekerjaan : "-" }}</td>
          <td>
            <div class="green-bedge" v-if="value.status === 'aktif'">Aktif</div>
            <div class="red-bedge" v-else>Nonaktif</div>
          </td>
        </tr>
        <tr v-if="list.length === 0">
          <td colspan="5" class="text-center">Tidak ada data</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";

// import TableLite from 'vue3-table-lite'
export default {
  name: "FamilyEmployee",
  components: {
    // FooterDashboard
    // Select2,
    // Datepicker
    // TableLite
  },

  props: {
    listFamily: Object,
    isEditable: Boolean,
  },

  data() {
    return {
      id_karyawan: this.$route.params.id,
      dataTable: "",
      list: this.$props.listFamily,
    };
  },

  mounted() {
    // this.dataTable = $("#tableFamily").DataTable({
    //   info: false,
    // });
    this.tooltip();
  },

  beforeUnmount() {
    if (this.dataTable) {
      this.dataTable.destroy();
      this.dataTable = "";
    }
  },

  methods: {
    tooltip() {
      $('[data-toggle="tooltipFamily"]').tooltip();
    },

    detail(id_family) {
      if (this.$props.isEditable) {
        $('[data-toggle="tooltipFamily"]').tooltip("hide");
        this.$router.push({
          name: "InsertFamily",
          params: {
            id: this.id_karyawan,
            nomor: id_family,
          },
        });
      }
    },
    showModal() {
      $("#modalTransfer").show("modal");
      console.log("asdasd");
    },
    myChangeEvent(val) {
      console.log(val);
    },
    mySelectEvent({ id, text }) {
      console.log({
        id,
        text,
      });
    },
  },
  watch: {
    listFamily: function (newVal) {
      if (this.dataTable) {
        this.dataTable.destroy();
      }
      this.list = newVal;
      setTimeout(() => {
        this.dataTable = $("#tableFamily").DataTable({
          info: false,
        });
      }, 500);
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>
