<template>
  <div
    class="modal fade"
    id="modalTransfer"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <form @submit.prevent="setTransfer">
          <div class="card-modal">
            <div class="modal-header">
              <h5 class="modal-title">Transfer Karyawan</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body mt-3">
              <div class="form-group">
                <label>Nama :</label>
                <input
                  type="text"
                  class="form-control align-self-center"
                  disabled
                  :value="formData.nama"
                  placeholder="Masukkan Nama Karyawan"
                />
              </div>

              <div class="form-group">
                <label>Tanggal Mulai :</label>
                <Datepicker
                  :autoApply="true"
                  v-model="formTransfer.tgl_mulai"
                  placeholder="Tanggal Mulai"
                  :format="format"
                  :enableTimePicker="false"
                  locale="id-ID"
                  selectText="Pilih"
                  cancelText="Batal"
                  :class="{
                    'is-invalid':
                      formErrorTransfer && formErrorTransfer.tgl_mulai,
                  }"
                  @update:modelValue="
                    changeDate($event, 'tgl_mulai', 'transfer')
                  "
                ></Datepicker>
              </div>

              <div class="form-group">
                <label>Departemen :</label>
                <Select2
                  v-model="formTransfer.id_divisi"
                  :options="OptionsDepartement"
                  placeholder="Plilih Departemen"
                  :settings="{dropdownParent: '#modalTransfer'}"
                  :class="{
                    'is-invalid':
                      formErrorTransfer && formErrorTransfer.id_divisi,
                  }"
                  @change="formChange('id_divisi', 'transfer')"
                  @select="formChange('id_divisi', 'transfer')"
                />
                <div
                  class="form-error"
                  v-if="formErrorTransfer && formErrorTransfer.id_divisi"
                >
                  {{ formTransfer.id_divisi }}
                </div>
              </div>

              <div class="form-group">
                <label>Jabatan :</label>
                <Select2
                  v-model="formTransfer.id_jabatan"
                  :options="OptionsPositions"
                  placeholder="Plilih Jabatan"
                  :class="{
                    'is-invalid':
                      formErrorTransfer && formErrorTransfer.id_jabatan,
                  }"
                  @change="formChange('id_jabatan', 'transfer')"
                  @select="formChange('id_jabatan', 'transfer')"
                />
                <div
                  class="form-error"
                  v-if="formErrorTransfer && formErrorTransfer.id_jabatan"
                >
                  {{ formTransfer.id_jabatan }}
                </div>
              </div>

              <div class="form-group">
                <label>Status Karyawan :</label>
                <Select2
                  v-model="formTransfer.status_karyawan"
                  :options="OptionsStatusEmployee"
                  placeholder="Plilih Status Karyawan"
                  :class="{
                    'is-invalid':
                      formErrorTransfer && formErrorTransfer.status_karyawan,
                  }"
                  @change="formChange('status_karyawan', 'transfer')"
                  @select="formChange('status_karyawan', 'transfer')"
                />
                <div
                  class="form-error"
                  v-if="formErrorTransfer && formErrorTransfer.status_karyawan"
                >
                  {{ formTransfer.status_karyawan }}
                </div>
              </div>
              <div class="form-group">
                <label>Alasan :</label>
                <textarea
                  class="form-control"
                  :class="{
                    'is-invalid': formErrorTransfer && formErrorTransfer.alasan,
                  }"
                  v-model="formTransfer.alasan"
                  @change="formChange('alasan', 'transfer')"
                  placeholder="Masukkan Alasan"
                ></textarea>
                <div
                  class="form-error"
                  v-if="formErrorTransfer && formErrorTransfer.alasan"
                >
                  {{ formTransfer.alasan }}
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                :disabled="iSTransfer"
                style="width: unset"
                class="btn btn-save"
              >
                <span class="fas fa-spinner fa-spin" v-if="iSTransfer"></span>
                Simpan
              </button>
              <button
                type="button"
                style="padding: 0.3rem 0.8rem; font-size: 12px; border: 0"
                class="btn btn-blue"
                @click="setTransferSync"
              >
                Simpan & Sync
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal fade" id="modalResign" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <form @submit.prevent="setResign">
          <div class="card-modal">
            <div class="modal-header">
              <h5 class="modal-title">{{ formResign.type == 'resign' ? 'Resign Karyawan' : 'Pemberhentian Karyawan' }}</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body mt-3">
              <div class="form-group">
                <label>Nama :</label>
                <input
                  type="text"
                  class="form-control align-self-center"
                  disabled
                  :value="formData.nama"
                  placeholder="Masukkan Nama Karyawan"
                />
              </div>

              <div class="form-group">
                <label>{{ formResign.type == 'resign' ? 'Tanggal Resign' : 'Tanggal Pemberhentian' }} :</label>
                <Datepicker
                  :autoApply="true"
                  v-model="formResign.tgl_selesai"
                  :placeholder="formResign.type == 'resign' ? 'Tanggal Resign' : 'Tanggal Pemberhentian'"
                  :format="format"
                  :enableTimePicker="false"
                  locale="id-ID"
                  selectText="Pilih"
                  cancelText="Batal"
                  :class="{
                    'is-invalid':
                      formErrorResign && formErrorResign.tgl_selesai,
                  }"
                  @update:modelValue="
                    changeDate($event, 'tgl_selesai', 'resign')
                  "
                ></Datepicker>
              </div>
              <div class="form-group">
                <label>Alasan :</label>
                <textarea
                  class="form-control"
                  :class="{
                    'is-invalid': formErrorResign && formErrorResign.alasan,
                  }"
                  v-model="formResign.alasan"
                  @keyup="formChange('alasan', 'resign')"
                  placeholder="Masukkan Alasan"
                ></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <button
                :disabled="iSResign"
                style="width: unset"
                class="btn btn-save"
              >
                <span class="fas fa-spinner fa-spin" v-if="iSResign"></span>
                Simpan
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">Edit Karyawan</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-karyawan">Karyawan</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Edit
                  </li>
                </ol>
              </nav>
            </div>

            <div class="button-edit" v-show="validated == 1 && formData.status == 'aktif'">
              <button class="btn btn-add" type="button" @click="editable">
                <img src="/img/icon-edit1.svg" alt="" style="width: 20px" />
                Edit
              </button>
            </div>
            <div v-show="validated != 1">
              <div class="dropdown d-inline-block" v-if="isEditable">
                <button class="btn btn-save btn-resign mr-2 dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                  Active
                </button>
                <div class="dropdown-menu">
                  <a class="dropdown-item" href="javascript:void(0)" @click="showModalResign('resign')">Resign</a>
                  <a class="dropdown-item" href="javascript:void(0)" @click="showModalResign('terminated')">Diberhentikan</a>
                </div>
              </div>
              <!-- <button
                class="btn btn-resign btn-save mr-1"
                type="button"
                v-if="isEditable"
                data-toggle="modal"
                data-target="#modalResign"
              >
                Resign
              </button> -->
              <button
                class="btn btn-save"
                :disabled="isSubmit"
                v-if="isEditable"
              >
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
            </div>
          </div>
          <div class="card">
            <div class="row">
              <div class="col-md-3" style="padding: 0px">
                <div class="col-left">
                  <div class="avatar text-center">
                    <img
                      :src="dataProfile.foto_profile"
                      alt=""
                      v-if="!isProcess"
                    />
                    <Skeletor circle size="170" class="m-auto" v-else />
                  </div>
                  <div v-if="!isProcess" class="name-employee text-center">
                    {{ dataProfile.nama }}
                  </div>
                  <div v-else class="name-employee text-center">
                    <Skeletor
                      width="80%"
                      height="40px"
                      class="rounded m-auto"
                    />
                  </div>
                  <div v-if="!isProcess" class="position text-center">
                    {{ dataProfile.position }}
                  </div>
                  <div v-else class="name-employee text-center">
                    <Skeletor
                      width="80%"
                      height="30px"
                      class="rounded m-auto"
                    />
                  </div>
                  <hr />

                  <div
                    class="nav flex-column nav-pills"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <a
                      class="nav-link active"
                      id="v-pills-home-tab"
                      data-toggle="pill"
                      href="#v-pills-home"
                      role="tab"
                      aria-controls="v-pills-home"
                      aria-selected="true"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        class="icon-detail"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 18C5 15.2386 7.23858 13 10 13H14C16.7614 13 19 15.2386 19 18V19.2C19 19.6418 18.6418 20 18.2 20H5.8C5.35817 20 5 19.6418 5 19.2V18Z"
                          fill="white"
                          stroke="#898989"
                          stroke-width="2"
                        />
                        <circle
                          cx="12"
                          cy="6"
                          r="4"
                          fill="white"
                          stroke="#898989"
                          stroke-width="2"
                        />
                      </svg>

                      Data Personal</a
                    >
                    <a
                      class="nav-link"
                      id="v-pills-education-tab"
                      data-toggle="pill"
                      href="#v-pills-education"
                      role="tab"
                      aria-controls="v-pills-education"
                      aria-selected="false"
                      ><svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.4899 10.1901L20.4899 9.64008L11.4899 4.64008H11.3799C11.3186 4.61416 11.255 4.59407 11.1899 4.58008H10.9999H10.8199C10.7516 4.59408 10.6846 4.61416 10.6199 4.64008H10.5099L1.50988 9.64008C1.35598 9.7273 1.22797 9.85378 1.13891 10.0066C1.04985 10.1595 1.00293 10.3332 1.00293 10.5101C1.00293 10.687 1.04985 10.8607 1.13891 11.0135C1.22797 11.1664 1.35598 11.2929 1.50988 11.3801L3.99988 12.7601V17.5001C3.99988 18.2957 4.31595 19.0588 4.87856 19.6214C5.44117 20.184 6.20423 20.5001 6.99988 20.5001H14.9999C15.7955 20.5001 16.5586 20.184 17.1212 19.6214C17.6838 19.0588 17.9999 18.2957 17.9999 17.5001V12.7601L19.9999 11.6401V14.5001C19.9999 14.7653 20.1052 15.0196 20.2928 15.2072C20.4803 15.3947 20.7347 15.5001 20.9999 15.5001C21.2651 15.5001 21.5194 15.3947 21.707 15.2072C21.8945 15.0196 21.9999 14.7653 21.9999 14.5001V11.0601C21.9996 10.8829 21.9522 10.7089 21.8626 10.5561C21.773 10.4032 21.6443 10.2769 21.4899 10.1901V10.1901ZM15.9999 17.5001C15.9999 17.7653 15.8945 18.0196 15.707 18.2072C15.5194 18.3947 15.2651 18.5001 14.9999 18.5001H6.99988C6.73466 18.5001 6.48031 18.3947 6.29277 18.2072C6.10523 18.0196 5.99988 17.7653 5.99988 17.5001V13.8701L10.5099 16.3701L10.6599 16.4301H10.7499C10.8329 16.4405 10.9169 16.4405 10.9999 16.4301C11.0829 16.4405 11.1669 16.4405 11.2499 16.4301H11.3399C11.393 16.4189 11.4437 16.3986 11.4899 16.3701L15.9999 13.8701V17.5001ZM10.9999 14.3601L4.05988 10.5001L10.9999 6.64008L17.9399 10.5001L10.9999 14.3601Z"
                          fill="#898989"
                        />
                      </svg>

                      Pendidikan</a
                    >
                    <a
                      class="nav-link"
                      id="v-pills-profile-tab"
                      data-toggle="pill"
                      href="#v-pills-profile"
                      role="tab"
                      aria-controls="v-pills-profile"
                      aria-selected="false"
                      ><svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 4C7.79086 4 6 5.79086 6 8C6 10.2091 7.79086 12 10 12C12.2091 12 14 10.2091 14 8C14 5.79086 12.2091 4 10 4ZM4 8C4 4.68629 6.68629 2 10 2C13.3137 2 16 4.68629 16 8C16 11.3137 13.3137 14 10 14C6.68629 14 4 11.3137 4 8ZM16.8284 3.75736C17.219 3.36683 17.8521 3.36683 18.2426 3.75736C20.5858 6.1005 20.5858 9.8995 18.2426 12.2426C17.8521 12.6332 17.219 12.6332 16.8284 12.2426C16.4379 11.8521 16.4379 11.219 16.8284 10.8284C18.3905 9.26633 18.3905 6.73367 16.8284 5.17157C16.4379 4.78105 16.4379 4.14788 16.8284 3.75736ZM17.5299 16.7575C17.6638 16.2217 18.2067 15.8959 18.7425 16.0299C20.0705 16.3618 20.911 17.2109 21.3944 18.1778C21.8622 19.1133 22 20.1571 22 21C22 21.5523 21.5523 22 21 22C20.4477 22 20 21.5523 20 21C20 20.3429 19.8878 19.6367 19.6056 19.0722C19.339 18.5391 18.9295 18.1382 18.2575 17.9701C17.7217 17.8362 17.3959 17.2933 17.5299 16.7575ZM6.5 18C5.24054 18 4 19.2135 4 21C4 21.5523 3.55228 22 3 22C2.44772 22 2 21.5523 2 21C2 18.3682 3.89347 16 6.5 16H13.5C16.1065 16 18 18.3682 18 21C18 21.5523 17.5523 22 17 22C16.4477 22 16 21.5523 16 21C16 19.2135 14.7595 18 13.5 18H6.5Z"
                          fill="#898989"
                        />
                      </svg>
                      Keluarga</a
                    >

                    <a
                      class="nav-link"
                      id="v-pills-messages-tab"
                      data-toggle="pill"
                      href="#v-pills-messages"
                      role="tab"
                      aria-controls="v-pills-messages"
                      aria-selected="false"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13 3C8.03 3 4 7.03 4 12H1L4.89 15.89L4.96 16.03L9 12H6C6 8.13 9.13 5 13 5C16.87 5 20 8.13 20 12C20 15.87 16.87 19 13 19C11.07 19 9.32 18.21 8.06 16.94L6.64 18.36C8.27 19.99 10.51 21 13 21C17.97 21 22 16.97 22 12C22 7.03 17.97 3 13 3ZM12 8V13L16.25 15.52L17.02 14.24L13.5 12.15V8H12Z"
                          fill="#898989"
                        />
                      </svg>

                      History</a
                    >
                    <a
                      class="nav-link"
                      id="v-pills-access-tab"
                      data-toggle="pill"
                      href="#v-pills-access"
                      role="tab"
                      aria-controls="v-pills-access"
                      aria-selected="false"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4 6H22V4H4C2.9 4 2 4.9 2 6V17H0V20H14V17H4V6ZM23 8H17C16.45 8 16 8.45 16 9V19C16 19.55 16.45 20 17 20H23C23.55 20 24 19.55 24 19V9C24 8.45 23.55 8 23 8ZM22 17H18V10H22V17Z"
                          fill="#898989"
                        />
                      </svg>

                      Hak Akses</a
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-9" style="padding: 0px">
                <div class="col-right">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="v-pills-home"
                      role="tabpanel"
                      style="padding: 20px"
                      aria-labelledby="v-pills-home-tab"
                    >
                      <div class="info-detail">
                        <div class="row">
                          <div class="col-12">
                            <div class="title">Detail Informasi</div>
                            <div class="sub-title">
                              Informasi detail dari karyawan
                            </div>
                          </div>
                        </div>
                        <div class="mt-4">
                          <div class="d-flex flex-row">
                            <div class="text align-self-center">
                              ID Karyawan
                            </div>
                            <input
                              type="text"
                              class="form-control align-self-center"
                              disabled="true"
                              :value="formData.id_karyawan"
                              placeholder="Masukkan  ID Karyawan"
                            />
                          </div>
                        </div>
                        <div class="mt-4">
                          <div class="d-flex flex-row">
                            <div class="text align-self-center">Nama</div>
                            <input
                              :disabled="validated == 1"
                              type="text"
                              v-model="formData.nama"
                              class="form-control align-self-center"
                              @keyup="formChange('nama')"
                              :class="{
                                'is-invalid': formError && formError.nama,
                              }"
                              placeholder="Masukkan Nama Karyawan"
                            />
                          </div>
                          <div
                            class="form-error"
                            v-if="formError && formError.nama"
                          >
                            {{ formError.nama }}
                          </div>
                        </div>
                        <div class="mt-4">
                          <div class="d-flex flex-row">
                            <div class="text align-self-center">
                              Nomor Telepon
                            </div>
                            <input
                              :disabled="validated == 1"
                              type="text"
                              v-model="formData.no_hp"
                              class="form-control align-self-center"
                              @keyup="formChange('no_hp')"
                              :class="{
                                'is-invalid': formError && formError.no_hp,
                              }"
                              placeholder="Masukkan Nomor Telepon"
                              v-maska="'0###############'"
                            />
                          </div>
                          <div
                            class="form-error"
                            v-if="formError && formError.no_hp"
                          >
                            {{ formError.no_hp }}
                          </div>
                        </div>
                        <div class="mt-4">
                          <div class="d-flex flex-row">
                            <div class="text align-self-center">Email</div>
                            <input
                              :disabled="validated == 1"
                              type="email"
                              v-model="formData.email"
                              class="form-control align-self-center"
                              @keyup="formChange('email')"
                              :class="{
                                'is-invalid': formError && formError.email,
                              }"
                              placeholder="Masukkan Email"
                            />
                          </div>
                          <div
                            class="form-error"
                            v-if="formError && formError.email"
                          >
                            {{ formError.email }}
                          </div>
                        </div>
                        <!-- <div class="mt-4">
                          <div class="d-flex flex-row">
                            <div class="text align-self-center">Hak Akses</div>
                            <div class="form-edit">
                              <Select2
                                v-model="formData.akses_id"
                                :options="OptionAccess"
                                placeholder="Hak Akses Pengguna"
                                id="hak_akses"
                                :class="{
                                  'is-invalid': formError && formError.akses_id,
                                }"
                                :settings="{ allowClear: true }"
                                @change="locationEvent($event, 'akses_id')"
                                @select="locationEvent($event, 'akses_id')"
                              />
                            </div>
                          </div>
                          <div
                            class="form-error"
                            v-if="formError && formError.akses_id"
                          >
                            {{ formError.akses_id }}
                          </div>
                        </div> -->
                        <div class="mt-4">
                          <div class="d-flex flex-row">
                            <div class="text align-self-center">
                              Nomor Induk Kependudukan
                            </div>
                            <input
                              :disabled="validated == 1"
                              type="text"
                              v-model="formData.no_ktp"
                              class="form-control align-self-center"
                              @keyup="formChange('no_ktp')"
                              :class="{
                                'is-invalid': formError && formError.no_ktp,
                              }"
                              placeholder="Masukkan Nomor Induk Kependudukan"
                            />
                          </div>
                          <div
                            class="form-error"
                            v-if="formError && formError.no_ktp"
                          >
                            {{ formError.no_ktp }}
                          </div>
                        </div>

                        <div class="mt-4">
                          <div class="d-flex flex-row">
                            <div class="text align-self-center">
                              Tanggal Lahir
                            </div>

                            <Datepicker
                              :disabled="validated == 1"
                              :autoApply="true"
                              v-model="formData.tanggal_lahir"
                              placeholder="Tanggal Lahir"
                              :maxDate="new Date()"
                              :format="format"
                              :enableTimePicker="false"
                              locale="id-ID"
                              selectText="Pilih"
                              cancelText="Batal"
                              :class="{
                                'is-invalid':
                                  formError && formError.tanggal_lahir,
                              }"
                              @update:modelValue="
                                changeDate($event, 'tanggal_lahir')
                              "
                            ></Datepicker>
                          </div>
                          <div
                            class="form-error"
                            v-if="formError && formError.tanggal_lahir"
                          >
                            {{ formError.tanggal_lahir }}
                          </div>
                        </div>

                        <div class="mt-4">
                          <div class="d-flex flex-row">
                            <div class="text align-self-center">
                              Jenis Kelamin
                            </div>
                            <div class="form-edit">
                              <div class="row">
                                <div
                                  class="col-12 mt-1"
                                  style="margin-bottom: 10px"
                                >
                                  <label
                                    v-for="(value, index) in OptionsGender"
                                    :key="index"
                                    :for="`gender${index}`"
                                    class="mr-2"
                                  >
                                    <input
                                      :disabled="validated == 1"
                                      type="radio"
                                      :id="`gender${index}`"
                                      :value="value"
                                      v-model="formData.jenis_kelamin"
                                      @change="formChange('jenis_kelamin')"
                                    />
                                    <div class="d-flex align-items-center">
                                      <span
                                        style="color: #263238; font-size: 12px"
                                        >{{ value }}</span
                                      >
                                    </div>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="form-error"
                            v-if="formError && formError.jenis_kelamin"
                          >
                            {{ formError.jenis_kelamin }}
                          </div>
                        </div>
                        <div class="mt-4">
                          <div class="d-flex flex-row">
                            <div class="text align-self-center">Rekening</div>
                            <Select2
                              :disabled="validated == 1"
                              placeholder="Pilih Bank"
                              class="align-self-center bank-options"
                              :options="dataBank"
                              style="width: 200px !important"
                              v-model="formData.nama_bank"
                              :class="{
                                'is-invalid': formError && formError.nama_bank,
                              }"
                              @change="formChange('nama_bank')"
                              @select="formChange('nama_bank')"
                            />
                            <input
                              :disabled="validated == 1"
                              type="text"
                              class="form-control align-self-center"
                              :class="{
                                'is-invalid':
                                  formError && formError.nomor_rekening,
                              }"
                              @keyup="formChange('nomor_rekening')"
                              v-model="formData.nomor_rekening"
                              placeholder="Masukkan  Nomor Rekening"
                              v-maska="'############################'"
                              style="
                                width: 100%;
                                border-top-left-radius: 0px;
                                border-bottom-left-radius: 0px;
                              "
                            />
                          </div>
                          <div
                            class="form-error"
                            v-if="
                              formError &&
                              (formError.nama_bank || formError.nomor_rekening)
                            "
                          >
                            {{
                              formError.nama_bank
                                ? formError.nama_bank
                                : formError.nomor_rekening
                            }}
                          </div>
                        </div>
                        <div class="mt-4">
                          <div class="d-flex flex-row">
                            <div class="text align-self-center">NPWP</div>
                            <input
                              :disabled="validated == 1"
                              type="text"
                              v-model="formData.npwp"
                              class="form-control align-self-center"
                              @keyup="formChange('npwp')"
                              :class="{
                                'is-invalid': formError && formError.npwp,
                              }"
                              v-maska="'##.###.###.#-###.###'"
                              placeholder="Masukkan  NPWP"
                            />
                          </div>
                          <div
                            class="form-error"
                            v-if="formError && formError.npwp"
                          >
                            {{ formError.npwp }}
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div class="info-detail">
                        <div class="row">
                          <div class="col-8">
                            <div class="title">Detail Alamat</div>
                            <div class="sub-title">
                              Informasi alamat dari karyawan
                            </div>
                          </div>
                          <div class="col-4 d-flex align-items-center justify-content-end" v-if="validated != 1">
                            <button type="button" @click="setAsResidenaceAddress" style="font-size: 12px;padding: 5px 5px;" class="btn btn-orange">Jadikan Alamat Domisili</button>
                          </div>
                        </div>
                        <div class="mt-4">
                          <div class="d-flex flex-row">
                            <div class="text align-self-center">Provinsi</div>
                            <div class="form-edit">
                              <Select2
                                :disabled="validated == 1"
                                v-model="formData.id_provinsi"
                                :options="OptionsProvince"
                                placeholder="Provinsi"
                                :class="{
                                  'is-invalid':
                                    formError && formError.id_provinsi,
                                }"
                                @change="locationEvent($event, 'id_provinsi')"
                                @select="locationEvent($event, 'id_provinsi')"
                              />
                            </div>
                          </div>
                          <div
                            class="form-error"
                            v-if="formError && formError.id_provinsi"
                          >
                            {{ formError.id_provinsi }}
                          </div>
                        </div>
                        <div class="mt-4">
                          <div class="d-flex flex-row">
                            <div class="text align-self-center">Kabupaten</div>
                            <div class="form-edit">
                              <Select2
                                :disabled="validated == 1"
                                v-model="formData.id_kabupaten"
                                :options="OptionsRegion"
                                placeholder="Kabupaten"
                                :settings="{
                                  settingOption: value,
                                  settingOption: value,
                                }"
                                :class="{
                                  'is-invalid':
                                    formError && formError.id_kabupaten,
                                }"
                                @change="locationEvent($event, 'id_kabupaten')"
                                @select="locationEvent($event, 'id_kabupaten')"
                              />
                            </div>
                          </div>
                          <div
                            class="form-error"
                            v-if="formError && formError.id_kabupaten"
                          >
                            {{ formError.id_kabupaten }}
                          </div>
                        </div>
                        <div class="mt-4">
                          <div class="d-flex flex-row">
                            <div class="text align-self-center">Kecamatan</div>
                            <div class="form-edit">
                              <Select2
                                :disabled="validated == 1"
                                v-model="formData.id_kecamatan"
                                :options="OptionsDistrict"
                                placeholder="Kecamatan"
                                :settings="{
                                  settingOption: value,
                                  settingOption: value,
                                }"
                                :class="{
                                  'is-invalid':
                                    formError && formError.id_kecamatan,
                                }"
                                @change="locationEvent($event, 'id_kecamatan')"
                                @select="locationEvent($event, 'id_kecamatan')"
                              />
                            </div>
                          </div>
                          <div
                            class="form-error"
                            v-if="formError && formError.id_kecamatan"
                          >
                            {{ formError.id_kecamatan }}
                          </div>
                        </div>

                        <div class="mt-4">
                          <div class="d-flex flex-row">
                            <div class="text align-self-center">Alamat</div>
                            <textarea
                              :disabled="validated == 1"
                              type="text"
                              v-model="formData.alamat"
                              style="height: unset !important"
                              rows="6"
                              class="form-control"
                              @keyup="formChange('alamat')"
                              :class="{
                                'is-invalid': formError && formError.alamat,
                              }"
                              placeholder="Masukkan Alamat"
                            ></textarea>
                          </div>
                          <div
                            class="form-error"
                            v-if="formError && formError.alamat"
                          >
                            {{ formError.alamat }}
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div class="info-detail">
                        <div class="row">
                          <div class="col-12">
                            <div class="title">Detail Alamat Domisili</div>
                            <div class="sub-title">
                              Informasi alamat domisili dari karyawan
                            </div>
                          </div>
                        </div>
                        <div class="mt-4">
                          <div class="d-flex flex-row">
                            <div class="text align-self-center">Provinsi</div>
                            <div class="form-edit">
                              <Select2
                                :disabled="validated == 1"
                                v-model="formData.domisili_provinsi"
                                :options="OptionsProvince"
                                placeholder="Provinsi"
                                :class="{
                                  'is-invalid':
                                    formError && formError.domisili_provinsi,
                                }"
                                @change="locationEvent($event, 'domisili_provinsi')"
                                @select="locationEvent($event, 'domisili_provinsi')"
                              />
                            </div>
                          </div>
                          <div
                            class="form-error"
                            v-if="formError && formError.domisili_provinsi"
                          >
                            {{ formError.domisili_provinsi }}
                          </div>
                        </div>
                        <div class="mt-4">
                          <div class="d-flex flex-row">
                            <div class="text align-self-center">Kabupaten</div>
                            <div class="form-edit">
                              <Select2
                                :disabled="validated == 1"
                                v-model="formData.domisili_kabupaten"
                                :options="OptionsRegionResidence"
                                placeholder="Kabupaten"
                                :settings="{
                                  settingOption: value,
                                  settingOption: value,
                                }"
                                :class="{
                                  'is-invalid':
                                    formError && formError.domisili_kabupaten,
                                }"
                                @change="locationEvent($event, 'domisili_kabupaten')"
                                @select="locationEvent($event, 'domisili_kabupaten')"
                              />
                            </div>
                          </div>
                          <div
                            class="form-error"
                            v-if="formError && formError.domisili_kabupaten"
                          >
                            {{ formError.domisili_kabupaten }}
                          </div>
                        </div>
                        <div class="mt-4">
                          <div class="d-flex flex-row">
                            <div class="text align-self-center">Kecamatan</div>
                            <div class="form-edit">
                              <Select2
                                :disabled="validated == 1"
                                v-model="formData.domisili_kecamatan"
                                :options="OptionsDistrictResidence"
                                placeholder="Kecamatan"
                                :settings="{
                                  settingOption: value,
                                  settingOption: value,
                                }"
                                :class="{
                                  'is-invalid':
                                    formError && formError.domisili_kecamatan,
                                }"
                                @change="locationEvent($event, 'domisili_kecamatan')"
                                @select="locationEvent($event, 'domisili_kecamatan')"
                              />
                            </div>
                          </div>
                          <div
                            class="form-error"
                            v-if="formError && formError.domisili_kecamatan"
                          >
                            {{ formError.domisili_kecamatan }}
                          </div>
                        </div>

                        <div class="mt-4">
                          <div class="d-flex flex-row">
                            <div class="text align-self-center">Alamat  Domisili</div>
                            <textarea
                              :disabled="validated == 1"
                              type="text"
                              v-model="formData.domisili_alamat"
                              style="height: unset !important"
                              rows="6"
                              class="form-control"
                              @keyup="formChange('domisili_alamat')"
                              :class="{
                                'is-invalid': formError && formError.domisili_alamat,
                              }"
                              placeholder="Masukkan Alamat Domisili"
                            ></textarea>
                          </div>
                          <div
                            class="form-error"
                            v-if="formError && formError.domisili_alamat"
                          >
                            {{ formError.domisili_alamat }}
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div class="info-detail">
                        <div class="row">
                          <div class="col-12">
                            <div class="title">Data Karyawan</div>
                            <div class="sub-title">
                              Informasi data dari karyawan
                            </div>
                          </div>
                        </div>
                        <div class="mt-4">
                          <div class="d-flex flex-row">
                            <div class="text align-self-center">
                              Atasan Karyawan
                            </div>
                            <div class="form-edit">
                              <Select2
                                v-model="formData.atasan_id"
                                :options="optionEmployee"
                                placeholder="Pilih Karyawan"
                                class="align-self-center"
                                :class="{
                                  'is-invalid':
                                    formError && formError.atasan_id,
                                }"
                                :disabled="validated == 1"
                                @change="formChange('atasan_id')"
                                @select="formChange('atasan_id')"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="mt-4">
                          <div class="d-flex flex-row">
                            <div class="text align-self-center">
                              Tanggal Masuk
                            </div>

                            <div class="form-edit">
                              <Datepicker
                                :autoApply="true"
                                v-model="formData.tgl_masuk"
                                placeholder="Tanggal Mulai"
                                :format="format"
                                :enableTimePicker="false"
                                locale="id-ID"
                                selectText="Pilih"
                                cancelText="Batal"
                                disabled="true"
                                :class="{
                                  'is-invalid':
                                    formError && formError.tgl_masuk,
                                }"
                                @update:modelValue="
                                  changeDate($event, 'tgl_masuk')
                                "
                              ></Datepicker>
                            </div>
                          </div>
                        </div>

                        <div class="mt-4">
                          <div class="d-flex flex-row">
                            <div class="text align-self-center">Departemen</div>
                            <div class="form-edit">
                              <Select2
                                v-model="formData.id_divisi"
                                :options="OptionsDepartement"
                                placeholder="Departemen"
                                class="align-self-center"
                                :class="{
                                  'is-invalid':
                                    formError && formError.id_divisi,
                                }"
                                @change="formChange('id_divisi')"
                                disabled="true"
                                @select="formChange('id_divisi')"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="mt-4">
                          <div class="d-flex flex-row">
                            <div class="text align-self-center">Jabatan</div>
                            <div class="form-edit">
                              <Select2
                                v-model="formData.id_jabatan"
                                :options="OptionsPositions"
                                placeholder="Jabatan"
                                class="align-self-center"
                                :class="{
                                  'is-invalid':
                                    formError && formError.id_jabatan,
                                }"
                                disabled="true"
                                @change="formChange('id_jabatan')"
                                @select="formChange('id_jabatan')"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="mt-4">
                          <div class="d-flex flex-row">
                            <div class="text align-self-center">
                              Status Karyawan
                            </div>
                            <div class="form-edit">
                              <Select2
                                v-model="formData.status_karyawan"
                                :options="OptionsStatusEmployee"
                                placeholder="Status Karyawan"
                                class="align-self-center"
                                :class="{
                                  'is-invalid':
                                    formError && formError.status_karyawan,
                                }"
                                disabled="true"
                                @change="formChange('status_karyawan')"
                                @select="formChange('status_karyawan')"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div class="info-detail" v-if="isPenggajian">
                        <div class="row">
                          <div class="col-12">
                            <div class="title">Data Penggajian</div>
                            <div class="sub-title">
                              Informasi data dari karyawan
                            </div>
                          </div>
                        </div>
                        <div class="mt-4">
                          <div class="d-flex flex-row">
                            <div class="text align-self-center">
                              Kategori Penggajian
                            </div>
                            <div class="form-edit">
                              <Select2
                                v-model="PayrollCategory"
                                :disabled="!isPenggajian || validated == 1"
                                :options="OptionsPayrollCategory"
                                placeholder="Kategori Penggajian"
                                class="align-self-center"
                                :settings="{
                                  settingOption: value,
                                  settingOption: value,
                                }"
                                @change="formChange($event)"
                                @select="formChange($event)"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="mt-4">
                          <div class="d-flex flex-row">
                            <div class="text align-self-center">
                              Kategori Pajak dan Pengurangan
                            </div>
                            <div class="form-edit">
                              <Select2
                                v-model="taxCategory"
                                :options="OptionsTaxCatgeory"
                                :disabled="!isPenggajian || validated == 1"
                                placeholder="Kategori Pajak dan Pengurangan"
                                class="align-self-center"
                                :settings="{
                                  settingOption: value,
                                  settingOption: value,
                                }"
                                @change="myChangeEvent($event)"
                                @select="mySelectEvent($event)"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="mt-4">
                          <div class="d-flex flex-row">
                            <div class="text align-self-center">
                              Kategori Prorata
                            </div>
                            <div class="form-edit">
                              <Select2
                                v-model="categoryProrated"
                                :options="OptionsCategoryProrated"
                                placeholder=" Kategori Prorata"
                                class="align-self-center"
                                :disabled="!isPenggajian || validated == 1"
                                :settings="{
                                  settingOption: value,
                                  settingOption: value,
                                }"
                                @change="myChangeEvent($event)"
                                @select="mySelectEvent($event)"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="mt-4">
                          <div class="d-flex flex-row">
                            <div class="text align-self-center">
                              Kategori THR
                            </div>
                            <div class="form-edit">
                              <Select2
                                v-model="thrCategory"
                                :options="OptionsTHRCategory"
                                placeholder="Kategori THR"
                                class="align-self-center"
                                :settings="{
                                  settingOption: value,
                                  settingOption: value,
                                }"
                                :disabled="!isPenggajian || validated == 1"
                                @change="myChangeEvent($event)"
                                @select="mySelectEvent($event)"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="mt-4">
                          <div class="d-flex flex-row">
                            <div class="text align-self-center">
                              Kategori Kompensasi Lembur
                            </div>
                            <div class="form-edit">
                              <Select2
                                v-model="compensationCategor"
                                :options="OptionsCompensationCategory"
                                placeholder="Kategori Kompensasi Lembur"
                                class="align-self-center"
                                :settings="{
                                  settingOption: value,
                                  settingOption: value,
                                }"
                                :disabled="!isPenggajian || validated == 1"
                                @change="myChangeEvent($event)"
                                @select="mySelectEvent($event)"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="mt-4">
                          <div class="d-flex flex-row">
                            <div class="text align-self-center">
                              BPJS Ketenagakerjaan
                            </div>
                            <div class="form-edit">
                              <input
                                type="text"
                                :disabled="!isPenggajian || validated == 1"
                                class="form-control align-self-center"
                                placeholder="Masukkan Nomor BPJS Ketenagakerjaan"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="mt-4">
                          <div class="d-flex flex-row">
                            <div class="text align-self-center">
                              BPJS Kesehatan
                            </div>
                            <div class="form-edit">
                              <input
                                type="text"
                                :disabled="!isPenggajian || validated == 1"
                                class="form-control align-self-center"
                                placeholder="Masukkan Nomor BPJS Kesehatan"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr v-if="formData.tgl_keluar" />
                      <div class="info-detail" v-if="formData.tgl_keluar">
                        <div class="row">
                          <div class="col-12">
                            <div class="title">Data Resign</div>
                            <div class="sub-title">
                              Informasi detail dari karyawan
                            </div>
                          </div>
                        </div>
                        <div class="mt-4">
                          <div class="d-flex flex-row"></div>
                          <div class="text align-self-center">
                            Tanggal {{ formData.status == 'diberhentikan' ? 'Diberhentikan' : 'Keluar' }}
                          </div>
                          <div class="form-edit">
                            <Datepicker
                              :autoApply="true"
                              v-model="formData.tgl_keluar"
                              placeholder="Tanggal Keluar"
                              :format="format"
                              :enableTimePicker="false"
                              locale="id-ID"
                              selectText="Pilih"
                              cancelText="Batal"
                              :disabled="true"
                              :class="{
                                'is-invalid': formError && formError.tgl_keluar,
                              }"
                              @update:modelValue="
                                changeDate($event, 'tgl_keluar')
                              "
                            ></Datepicker>
                          </div>
                        </div>

                        <!-- <div class="mt-4">
                          <div class="d-flex flex-row"></div>
                          <div class="text align-self-center">Kompensasi</div>
                          <input
                            type="text"
                            class="form-control align-self-center text-right"
                            value="Rp. 70,000,000"
                            placeholder="Masukkan Kompensasi"
                          />
                        </div> -->
                        <div class="mt-4">
                          <div class="d-flex flex-row"></div>
                          <div class="text align-self-center">
                            Alasan {{ formData.status == 'diberhentikan' ? 'Diberhentikan' : 'Keluar' }}
                          </div>
                          <div class="form-edit">
                            <textarea
                              type="text"
                              class="form-control"
                              v-model="formData.alasan_keluar"
                              :disabled="true"
                              placeholder="Masukkan Alasan keluar"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="v-pills-education"
                      role="tabpanel"
                      aria-labelledby="v-pills-education-tab"
                      style="padding: 50px"
                      :style="{
                        padding: '50px',
                        minHeight: minHeight ? minHeight : '400px',
                      }"
                    >
                      <education-employee
                        :listPendidikan="listPendidikan"
                        :height="minHeight"
                        :isEditable="isEditable"
                      ></education-employee>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="v-pills-profile"
                      role="tabpanel"
                      aria-labelledby="v-pills-profile-tab"
                      :style="{
                        padding: '50px',
                        minHeight: minHeight ? minHeight : '400px',
                      }"
                    >
                      <family-employee
                        :listFamily="listKeluarga"
                        :isEditable="isEditable"
                      ></family-employee>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="v-pills-messages"
                      role="tabpanel"
                      aria-labelledby="v-pills-messages-tab"
                      :style="{
                        padding: '50px 50px 0px 50px',
                        minHeight: minHeight ? minHeight : '400px',
                      }"
                    >
                      <history-employee
                        :status-employee="formData.status"
                        :listTransfer="listTransfer"
                        :isEditable="isEditable"
                        :listPayroll="listPayroll"
                      ></history-employee>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="v-pills-access"
                      role="tabpanel"
                      aria-labelledby="v-pills-access-tab"
                      :style="{
                        padding: '50px 50px 0px 50px',
                        minHeight: minHeight ? minHeight : '400px',
                      }"
                    >
                      <due-access
                        :listdata="formData.hak_akses_raw"
                        :access="formData.hak_akses"
                        :accessDivisi="formData.akses_departement"
                        :listAccessDivisi="formData.akses_departement_raw"
                        :listDivisi="OptionsDepartement"
                        @update-access="setAccess"
                        @update-role="setRole"
                      ></due-access>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";
import Datepicker from "vue3-date-time-picker";
import HistoryEmployee from "./PositionHistory.vue";
import FamilyEmployee from "./FamilyEmployee.vue";
import EducationEmployee from "./EducationEmployee.vue";
import DueAccess from "./DueAccess.vue";

import { ref } from "vue";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import {
  get_ListBank,
  get_ListDivisi,
  get_ListKabupaten,
  get_ListKecamatan,
  get_ListProvinsi,
} from "../../../actions/master";
import { DATA, ROLE } from "../../../constans/Url";
import {
  checkModuleAccess,
  checkRules,
  cksClient,
  showAlert,
} from "../../../helper";
import { maska } from "maska";
import moment from "moment";
import "moment/locale/id";
import {
  get_KaryawanDetail,
  get_KaryawanMasterStatus,
  post_KaryawanSave,
  post_KaryawanTransferSave,
  post_KaryawanResignSave,
  get_KaryawanList,
} from "../../../actions/karyawan";
import { get_ListPosition } from "../../../actions/company/posistion";
import { Skeletor } from "vue-skeletor";

import $ from "jquery";
import { get_CompanyAccessList } from "../../../actions/company/access";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    SidebarDashboard,
    NavbarDashboard,
    HistoryEmployee,
    FamilyEmployee,
    EducationEmployee,
    Select2,
    Datepicker,
    Skeletor,
    DueAccess,
  },

  directives: {
    maska,
  },

  data() {
    return {
      validated: 1,
      editableDueaccess: true,
      checkAccess: checkModuleAccess,
      dateOut: null,
      dataBank: [],
      OptionsGender: ["Laki - Laki", "Perempuan"],
      OptionsDistrict: [],
      OptionsRegion: [],
      OptionsProvince: [],
      OptionsDistrictResidence: [],
      OptionsRegionResidence: [],
      OptionsDepartement: [],
      OptionsPositions: [],
      OptionsStatusEmployee: [],
      listPendidikan: [],
      listKeluarga: [],
      listTransfer: [],
      minHeight: "",
      OptionAccess: [],
      optionEmployee: [],
      //FORMDATA START
      isPenggajian: false,
      isProcess: true,
      isEditable: true,
      id_company: cksClient().get("_account").id_company,
      showPassword: false,
      id_karyawan: this.$route.params.id,
      formData: {
        id_karyawan: this.$route.params.id,
        id_company: cksClient().get("_account").id_company,
        url: location.origin,
        nama: "",
        email: "",
        no_hp: "",
        jenis_kelamin: "Laki - Laki",
        tanggal_lahir: "",
        no_ktp: "",
        nama_bank: "",
        nomor_rekening: "",
        npwp: "",
        alamat: "",
        id_provinsi: "",
        id_kecamatan: "",
        id_kabupaten: "",
        domisili_alamat: "",
        domisili_kecamatan: "",
        domisili_kabupaten: "",
        domisili_provinsi: "",
        tgl_masuk: "",
        id_jabatan: "",
        id_divisi: "",
        status_karyawan: "",
        hak_akses: false,
        hak_akses_raw: ROLE,
        akses_departement: false,
        akses_departement_raw: [],
        password: "",
        tgl_keluar: "",
        alasan_keluar: "",
        atasan_id: '',
      },
      formError: [],
      rules: {
        nama: {
          required: true,
        },
        email: {
          required: true,
          email: true,
        },
        no_hp: {
          required: true,
        },
        jenis_kelamin: {
          required: true,
        },
        tanggal_lahir: {
          required: true,
        },
        no_ktp: {
          required: true,
        },
        nama_bank: {
          required: true,
        },
        nomor_rekening: {
          required: true,
        },
        npwp: {
          required: true,
        },
        alamat: {
          required: true,
        },
        id_provinsi: {
          required: true,
        },
        id_kecamatan: {
          required: true,
        },
        id_kabupaten: {
          required: true,
        },
        domisili_alamat: {
          required: true,
        },
        domisili_kecamatan: {
          required: true,
        },
        domisili_kabupaten: {
          required: true,
        },
        domisili_provinsi: {
          required: true,
        },
        tgl_masuk: {
          required: true,
        },
        id_jabatan: {
          required: true,
        },
        id_divisi: {
          required: true,
        },
        status_karyawan: {
          required: true,
        },
        akses_id: {
          required: false,
        },
        tgl_keluar: {
          required: false,
        },
        alasan_keluar: {
          required: false,
        },
        atasan_id: {
          required: false,
        }
      },
      isSubmit: false,
      dataProfile: {
        nama: "",
        position: "",
        foto_profile: "",
      },
      //FORMDATA END

      //FORMTRANSFER START
      iSTransfer: false,
      formTransfer: {
        id_karyawan: this.$route.params.id,
        id_company: cksClient().get("_account").id_company,
        id_divisi: "",
        id_jabatan: "",
        status_karyawan: "",
        alasan: "",
        tgl_mulai: "",
        isSync: false,
      },
      formErrorTransfer: [],
      rulesTransfer: {
        id_divisi: {
          required: true,
        },
        id_jabatan: {
          required: true,
        },
        status_karyawan: {
          required: true,
        },
        alasan: {
          required: false,
        },
        tgl_mulai: {
          required: true,
        },
      },
      //FORMTRANSFER END

      //FORMTRANSFER START
      iSResign: false,
      formResign: {
        id_karyawan: this.$route.params.id,
        id_company: cksClient().get("_account").id_company,
        alasan: "",
        tgl_selesai: "",
        type: 'resign'
      },
      formErrorResign: [],
      rulesResign: {
        alasan: {
          required: true,
        },
        tgl_selesai: {
          required: true,
        },
      },
      //FORMTRANSFER END
      listPayroll: [],
    };
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  created() {
    this.getKaryawan()
    this.getDataBank();
    this.getProvinsi();
    this.getDivisi();
    this.getStatus();
    this.getJabatan();
    this.getAccess();
    this.getData();
    this.isEditable = this.checkAccess("employee", "cru_employee")
      ? true
      : false;
  },

  mounted() {
    this.minHeight =
      document.getElementsByClassName("col-left")[0].getBoundingClientRect()
        .height -
      2 +
      "px";
    $(".tab-pane.fade").removeClass("show active");
    if (localStorage.getItem("familyTab")) {
      setTimeout(() => {
        $("#v-pills-profile-tab").tab("show");
        $("#v-pills-profile").addClass("show active");
        localStorage.removeItem("familyTab");
      }, 600);
    } else if (localStorage.getItem("eduTab")) {
      setTimeout(() => {
        $("#v-pills-education-tab").tab("show");
        $("#v-pills-education").addClass("show active");
        localStorage.removeItem("eduTab");
      }, 600);
    } else {
      setTimeout(() => {
        $("#v-pills-home-tab").tab("show");
        $("#v-pills-home").addClass("show active");
      }, 600);
    }
  },
  unmounted() {
    $("#v-pills-home-tab").tab("show");
  },
  methods: {
    setAsResidenaceAddress(){
      this.OptionsDistrictResidence = [...this.OptionsDistrict];
      this.OptionsRegionResidence = [...this.OptionsRegion];
      this.formData.domisili_alamat = this.formData.alamat;
      this.formData.domisili_kecamatan = this.formData.id_kecamatan;
      this.formData.domisili_kabupaten = this.formData.id_kabupaten;
      this.formData.domisili_provinsi = this.formData.id_provinsi;
      this.formChange('domisili_alamat');
    },
    editable() {
      this.validated = 2;
      this.editableDueaccess = false;
      this.$emit(this.editable);
    },
    getData() {
      this.isProcess = true;
      get_KaryawanDetail(
        this.id_karyawan,
        (res) => {
          var { data, pendidikan, keluarga, history_transfer, payroll } = res;
          delete data.created_at;
          delete data.password;
          delete data.updated_at;
          delete data.expired_password_reset;
          this.getKabupaten(data.id_provinsi);
          this.getKecamatan(data.id_kabupaten);
          this.getKabupaten(data.domisili_provinsi, 'domisili');
          this.getKecamatan(data.domisili_kabupaten, 'domisili');
          this.formData = data;
          this.formData.hak_akses = data.hak_akses === "true" ? true : false;
          this.formData.hak_akses_raw = data.hak_akses_raw
            ? JSON.parse(data.hak_akses_raw)
            : [];
          this.formData.akses_departement =
            data.akses_departement == "true" || data.akses_departement == 1
              ? true
              : false;
          this.formData.akses_departement_raw = data.akses_departement_raw
            ? data.akses_departement_raw.split("|")
            : [];
          this.formData.tanggal_lahir = moment(data.tanggal_lahir).format(
            "YYYY-MM-DD"
          );
          this.formData.tgl_keluar = data.tgl_keluar
            ? moment(data.tgl_keluar).format("YYYY-MM-DD")
            : "";
          this.isEditable =
            this.isEditable && data.status == "aktif" ? true : false;
          setTimeout(() => {
            this.formData.nama_bank = data.nama_bank;
          }, 1500);
          this.OptionsRegion = this.dataProfile.nama = data.nama;
          this.dataProfile.foto_profile = DATA.KARYAWAN(data.foto_profile);
          this.dataProfile.position = `${data.jabatan} - ${data.divisi}`;
          delete data.divisi;
          delete data.jabatan;
          this.isProcess = false;
          this.listPendidikan = pendidikan;
          this.listKeluarga = keluarga;
          this.listTransfer = history_transfer;
          this.listPayroll = payroll;
          setTimeout(() => {
            this.minHeight =
              document
                .getElementsByClassName("col-left")[0]
                .getBoundingClientRect().height -
              2 +
              "px";
          }, 500);
        },
        () => {
          showAlert(this.$swal, {
            title: "PERHATIAN!",
            msg: "Data karyawan tidak ditemukan",
            showCancelButton: true,
            showConfirmButton: false,
            onCancel: () => {
              this.$router.push({
                name: "DataEmployee",
              });
            },
          });
        }
      );
    },
    setAccess($event) {
      if ($event.tipe == "access") {
        this.formData.hak_akses = $event.value;
      } else if ($event.tipe == "divisi") {
        this.formData.akses_departement = $event.value;
      }
    },
    setRole($event) {
      if ($event.tipe == "access") {
        this.formData.hak_akses_raw = $event.value;
      } else if ($event.tipe == "divisi") {
        this.formData.akses_departement_raw = $event.value;
      }
    },
    //MASTER
    getAccess() {
      get_CompanyAccessList(
        {
          id_company: this.id_company,
        },
        (res) => {
          this.OptionAccess = res.list;
        }
      );
    },
    async getDataBank() {
      get_ListBank((res) => {
        this.dataBank = res.list;
      });
    },
    getKaryawan() {
      get_KaryawanList(
        {
          id_company: cksClient().get("_account").id_company,
          search: "aktif",
          id_divisi: "",
        },
        async (res) => {
          var { list } = res;
          this.optionEmployee = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key]
              if (element.id != this.formData.id_karyawan) {
                this.optionEmployee.push({
                  id: element.id,
                  text: element.nama,
                });
              }
            }
          }
        }
      );
    },
    async getProvinsi() {
      await get_ListProvinsi(
        (res) => {
          var { list } = res;
          var arr = [{ id: "", value: "" }];
          for (const key in list) {
            var data = list[key];
            data.text = list[key].nama;
            arr.push(data);
          }
          this.OptionsProvince = arr;
        },
        () => {
          this.OptionsProvince = [];
        }
      );
    },
    async getKabupaten(id, type = '') {
      await get_ListKabupaten(
        id,
        (res) => {
          var { list } = res;
          var arr = [{ id: "", value: "" }];
          for (const key in list) {
            var data = list[key];
            data.text = list[key].nama;
            arr.push(data);
          }
          if (type != '') {
            this.OptionsRegionResidence = arr;
          } else {
            this.OptionsRegion = arr;
          }
        },
        () => {
          if (type != '') {
            this.OptionsRegionResidence = [];
          } else {
            this.OptionsRegion = [];
          }
        }
      );
    },

    async getKecamatan(id, type = '') {
      await get_ListKecamatan(
        id,
        (res) => {
          var { list } = res;
          var arr = [{ id: "", value: "" }];
          for (const key in list) {
            var data = list[key];
            data.text = list[key].nama;
            arr.push(data);
          }
          if (type != '') {
            this.OptionsDistrictResidence = arr;
          } else {
            this.OptionsDistrict = arr;
          }
        },
        () => {
          if (type != '') {
            this.OptionsDistrictResidence = [];
          } else {
            this.OptionsDistrict = [];
          }
        }
      );
    },
    locationEvent(val, key) {
      this.formChange(key);
      if (key === "id_provinsi") {
        this.OptionsDistrict = [];
        this.OptionsRegion = [];
        this.getKabupaten(val.id);
      } else if (key === "id_kabupaten") {
        this.OptionsDistrict = [];
        this.getKecamatan(val.id);
      } else if (key == "domisili_provinsi") {
        this.OptionsDistrictResidence = [];
        this.OptionsRegionResidence = [];
        this.getKabupaten(val.id, 'domisili');
      } else if (key == "domisili_kabupaten") {
        this.OptionsDistrictResidence = [];
        this.getKecamatan(val.id, 'domisili');
      }
    },
    async formChange(key, tipe = "") {
      if (tipe == "transfer") {
        if (this.rulesTransfer[key]) {
          this.rulesTransfer[key].changed = true;
        }
        var checkTransfer = await checkRules(
          this.rulesTransfer,
          this.formTransfer
        );
        this.formErrorTransfer = checkTransfer.error;
      } else if (tipe === "resign") {
        if (this.rulesResign[key]) {
          this.rulesResign[key].changed = true;
        }
        var checkResign = await checkRules(this.rulesResign, this.formResign);
        this.formErrorResign = checkResign.error;
      } else {
        if (this.rules[key]) {
          this.rules[key].changed = true;
        }
        var check = await checkRules(this.rules, this.formData);
        this.formError = check.error;
      }
    },
    getDivisi() {
      get_ListDivisi({ id_company: this.id_company }, (res) => {
        const { list } = res;
        this.OptionsDepartement = [];
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const element = list[key];
            this.OptionsDepartement.push(element);
          }
        }
      });
    },
    getStatus() {
      get_KaryawanMasterStatus(this.id_company, (res) => {
        const { list } = res;
        this.OptionsStatusEmployee = list;
      });
    },
    getJabatan() {
      get_ListPosition({ id_company: this.id_company }, (res) => {
        const { list } = res;
        this.OptionsPositions = [];
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const element = list[key];
            if (element.status == "aktif") {
              this.OptionsPositions.push({
                id: element.id,
                text: element.nama,
              });
            }
          }
        }
      });
    },
    changeDate(data, key, tipe = "") {
      if (tipe == "transfer") {
        this.formTransfer[key] = moment(data).format("YYYY-MM-DD");
      } else if (tipe == "resign") {
        this.formResign[key] = moment(data).format("YYYY-MM-DD");
      } else {
        this.formData[key] = moment(data).format("YYYY-MM-DD");
      }
      this.formChange(key, tipe);
    },
    //END MASTER

    postData() {
      var data = { ...this.formData };
      data.hak_akses_raw = JSON.stringify(data.hak_akses_raw);
      this.isSubmit = true;
      post_KaryawanSave(
        data,
        (res) => {
          this.isSubmit = false;
          var msg =
            res.response_code === 201
              ? "Data karyawan berhasil dicatat"
              : "Data karyawan berhasil diperbarui";
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
            onSubmit: () => {
              this.$router.push({
                name: "DataEmployee",
              });
            },
          });
        },
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi kesalahan, silakan ulangi kembali nanti",
          });
        }
      );
    },
    async onSubmit() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      }
    },

    postTransfer() {
      this.iSTransfer = true;
      post_KaryawanTransferSave(
        this.formTransfer,
        (res) => {
          this.iSTransfer = false;
          this.listTransfer = res.list;
          var elActive = res.list.find((e) => e.status == "aktif");
          this.dataProfile.position = `${elActive.jabatan} - ${elActive.divisi}`;
          var msg =
            res.response_code == 201 ? "Karyawan berhasil dipindahkan" : "";
          $("#modalTransfer").modal("hide");
          setTimeout(() => {
            showAlert(this.$swal, {
              title: "BERHASIL!",
              msg: msg,
            });
          }, 500);
        },
        () => {
          this.iSTransfer = false;
          $("#modalTransfer").modal("hide");
          setTimeout(() => {
            showAlert(this.$swal, {
              title: "GAGAL!",
              msg: "Terjadi kesalahan, silakan ulangi kembali nanti",
              showCancelButton: true,
              showConfirmButton: false,
            });
          }, 500);
        }
      );
    },
    async setTransfer() {
      for (const key in this.rulesTransfer) {
        if (Object.hasOwnProperty.call(this.rulesTransfer, key)) {
          this.rulesTransfer[key].changed = true;
        }
      }
      var check = await checkRules(this.rulesTransfer, this.formTransfer);
      this.formErrorTransfer = check.error;
      if (check.success) {
        this.formTransfer.isSync = false;
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postTransfer();
          },
        });
      }
    },

    async setTransferSync() {
      for (const key in this.rulesTransfer) {
        if (Object.hasOwnProperty.call(this.rulesTransfer, key)) {
          this.rulesTransfer[key].changed = true;
        }
      }
      var check = await checkRules(this.rulesTransfer, this.formTransfer);
      this.formErrorTransfer = check.error;
      if (check.success) {
        this.formTransfer.isSync = true;
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postTransfer();
          },
        });
      }
    },

    postResign() {
      this.iSResign = true;
      post_KaryawanResignSave(
        this.formResign,
        (res) => {
          this.getData();
          this.iSResign = false;
          this.listResign = res.list;
          this.isEditable = false;
          var msg =
            res.response_code == 201
              ? "Data Berhasil disimpan"
              : "Data Berhasil disimpan";
          $("#modalResign").modal("hide");
          setTimeout(() => {
            showAlert(this.$swal, {
              title: "BERHASIL!",
              msg: msg,
            });
          }, 500);
        },
        () => {
          this.iSResign = false;
          $("#modalResign").modal("hide");
          setTimeout(() => {
            showAlert(this.$swal, {
              title: "GAGAL!",
              msg: "Terjadi kesalahan, silakan ulangi kembali nanti",
              showCancelButton: true,
              showConfirmButton: false,
            });
          }, 500);
        }
      );
    },
    showModalResign(type = 'resign') {
      this.formResign.type = type;
      $('#modalResign').modal('show');
    },
    async setResign() {
      for (const key in this.rulesResign) {
        if (Object.hasOwnProperty.call(this.rulesResign, key)) {
          this.rulesResign[key].changed = true;
        }
      }
      var check = await checkRules(this.rulesResign, this.formResign);
      this.formErrorResign = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postResign();
          },
        });
      }
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
.nav-pills .nav-link:hover svg path,
.nav-pills .nav-link.active svg circle,
.nav-pills .nav-link.active svg path {
  fill: #000 !important;
}

.nav-pills .nav-link:hover {
  color: #000;
}

.nav-pills .nav-link:hover .icon-detail path,
.nav-pills .nav-link:hover .icon-detail circle,
.nav-pills .nav-link.active .icon-detail circle,
.nav-pills .nav-link.active .icon-detail path {
  stroke: #000;
  fill: #fff !important;
}

.form-label {
  max-width: 252px;
  width: 197px;
}

.avatar img {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  object-fit: cover;
}

.info-detail .title {
  font-size: 18px;
  font-weight: 500;
}

.info-detail .sub-title {
  color: #c6c6c6;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}

.form-edit {
  width: 100%;
}

.col-left {
  padding: 30px 26px;
  border: 1px solid #eceff1;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-right: none;
}

.col-right {
  border: 1px solid #eceff1;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.title-detail {
  padding: 30px;
}

.text {
  color: #000;
  font-size: 15px;
  font-weight: 500;
  width: 488px;
  max-width: 236px;
}

.modal .text {
  color: #000;
  font-size: 13px;
}

.value {
  color: #000;
  font-size: 16px;
  font-weight: 400;
}

.info-detail {
  padding: 30px;
}

hr {
  margin: 0px;
  padding: 0px;
}

.col-left hr {
  margin: 20px 0px;
}

.row {
  /* border: 1px solid #ECEFF1; */
  border-radius: 10px;
}

.name-employee {
  font-size: 18px;
  font-weight: 600;
  margin-top: 1rem;
}

.position {
  color: #c6c6c6;
  font-size: 14px;
  margin-top: 0.5rem;
}

.menu .nav-link {
  color: #000;
}

.menu .nav-link.active {
  background: #68caf8;
  color: #fff;
  border-radius: 5px;
  padding: 10px 30px;
}

.menu {
  border: none;
  background: none;
}

.list-menu {
  margin-top: 30px;
}

ul li {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

ul {
  margin: 0px;
  padding: 0px;
}

.btn-save {
  background-color: #40ddd4;
  color: #fff;
  /* width: 90px; */
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0px;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.card .nav-pills {
  line-height: 30px;
}

.card .nav .nav-item {
  margin-right: 30px;
}

.card {
  padding: 63px 50px;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
  color: #000;
}

.nav-pills .nav-link {
  color: #898989;
}

.nav-pills .nav-link svg {
  margin-right: 10px;
}

.btn-resign {
  background: transparent !important;
  border-color: #ff3d3d !important;
  color: #ff3d3d !important;
}
.btn-resign:hover {
  background: #ff3d3d !important;
  color: #fff !important;
}
</style>
